<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="View Cash Advance" :active="isActive" v-on:update:active="emitModalIsActive">
      <div :class="{'opacity-50 pointer-events-none': isLoadingGetInitData}">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nomor</label>
            <vs-input class="w-full" :value="data.no_cash_advance" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Pengajuan</label>
            <vs-input class="w-full" :value="data.tgl_pengajuan" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Staf</label>
            <vs-input class="w-full" :value="data.nama_staf" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-input class="w-full" :value="data.nama_proyek" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Kategori</label>
            <vs-input class="w-full" :value="data.kategori" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Cara Bayar</label>
            <vs-input class="w-full" :value="data.nama_cara_bayar" readonly/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Judul</label>
            <vs-input class="w-full" :value="data.judul" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" :value="data.keterangan" readonly/>
          </div>
        </div>

        <div v-if="data.kategori === 'BUSINESS TRAVEL'">
          <vs-divider class="mt-base">Detail DLK</vs-divider>

          <div class="vx-row mb-3">
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Nomor</label>
              <vs-input class="w-full" :value="data.no_dlk" readonly/>
            </div>
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Tgl. Mulai</label>
              <vs-input class="w-full" :value="data.tgl_mulai" readonly/>
            </div>
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Tgl. Selesai</label>
              <vs-input class="w-full" :value="data.tgl_selesai" readonly/>
            </div>
            <div class="vx-col sm:w-3/12 w-full">
              <label class="ml-1 text-xs">Tempat Tujuan</label>
              <vs-input class="w-full" :value="data.tempat_tujuan" readonly/>
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-12/12 w-full">
              <label class="ml-1 text-xs">Tujuan Dinas</label>
              <vs-input class="w-full" :value="data.tujuan_dinas" readonly/>
            </div>
          </div>
        </div>

        <vs-divider class="mt-base">Estimasi Pengeluaran</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-table :data="data.details" stripe>
              <template slot="thead">
                <vs-th class="whitespace-no-wrap">Nomor</vs-th>
                <vs-th class="whitespace-no-wrap">Nama Item</vs-th>
                <vs-th class="whitespace-no-wrap">Nominal</vs-th>
                <vs-th class="whitespace-no-wrap">Files</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td class="">{{ item.no_cash_advance_d }}</vs-td>
                  <vs-td class="">{{ item.nama_item }}</vs-td>
                  <vs-td class="">{{ item.nominal | idr }}</vs-td>
                  <vs-td>
                    <div class="flex items-center space-x-2">
                      <span v-if="item.files_url.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item)">Lihat Files</span>
                      <span v-else class="text-sm">-</span>
                    </div>
                  </vs-td>
                </vs-tr>
                <vs-tr v-if="data.length > 0">
                  <vs-td class="font-bold"></vs-td>
                  <vs-td class="font-bold">GRAND TOTAL :</vs-td>
                  <vs-td class="font-bold">{{ grandTotal | idr }}</vs-td>
                  <vs-td class="font-bold"></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>

      <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>

    </vs-popup>
  </div>
</template>

<script>
import CashAdvanceRepository from '@/repositories/procurement/cash-advance-repository'
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'CashAdvanceEdit',
  props: ['isActive', 'idCashAdvance'],
  components: {
    FilesViewer
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      isLoadingGetInitData: false,
      data: {
        details: []
      },
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.details, item => parseFloat(item.nominal))
    }
  },
  methods: {
    getInitData () {
      this.isLoadingGetInitData = true

      CashAdvanceRepository.show(this.idCashAdvance)
        .then(response => {
          this.data = response.data.data
          this.data.details = _.map(this.data.details, item => {
            item.uuid = uuid()
            item.bukti_nota = null
            return item
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingGetInitData = false
        })
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks', 'caraBayars'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
